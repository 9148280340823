<template>
    <div>
        <div class="main-content">
            <div class="content">
                <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px" height="1%">
                    <el-table-column prop="goods_name" label="商品主图" align="center">
                        <template slot-scope="scope">
                            <img class="goods-img" :src="scope.row.img_url" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
                    <el-table-column prop="category_name" label="商品分类" align="center" width="150"></el-table-column>
                    <el-table-column prop="courier_name" label="所在小邮局" align="center"></el-table-column>
                    <el-table-column prop="stock" label="库存" align="center" width="130"></el-table-column>
                    <el-table-column prop="price" label="价格" align="center" width="150"></el-table-column>
                    <el-table-column prop="market_price" label="市场价" align="center" width="150"></el-table-column>
                    <el-table-column prop="is_put" label="是否上架" align="center" width="100">
                        <template slot-scope="scope">
                            <span>{{scope.row.is_put == 0?'否':'是'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="创建时间" align="center" width="170"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" @click="takeOff(scope.row)" v-if="scope.row.is_put == 1">下架</el-link>
                            <el-link type="danger" :underline="false" @click="delBtn(scope.row)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pages-center"
                               :current-page="listPages.currentPageNum"
                               :page-size="listPages.eachPageNum"
                               layout="prev, pager, next, jumper"
                               :total="listPages.total"
                               @current-change="pageCurrentChange">
                </el-pagination>
                <el-dialog :title="this.btnChoose === 'del'?'删除原因':'下架原因'" :visible.sync="dialogVisible" width="40%"
                           custom-class="custom-dialog"
                           :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
                    <el-form :model="addForm" :rules="rules" ref="addForm" class="demo-ruleForm">
                        <el-form-item label="原因" prop="note" label-position="left">
                            <vue-tinymce :setting="setting" v-model="addForm.note"></vue-tinymce>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" style="text-align: center">
                        <el-button @click="dialogVisible = false">取消</el-button>
                        <el-button type="primary" @click="submitForm('addForm')" style="margin-left: 30px">确定
                        </el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
    import {goodsListAdmin, goods_adminDelGoods, goods_downGoods} from '@/config/apis'

    export default {
        data() {
            return {
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                btnChoose: null,
                dialogVisible: false,
                addForm: {
                    goods_id: null,
                    note: '',
                },
                rules: {
                    note: {required: true, message: '请输入原因', trigger: 'blur'},
                },
                setting: {
                    menubar: false,
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', //本地化设置
                    height: 300,
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    paging: '1',
                    pageSize: this.listPages.eachPageNum,
                    page: this.listPages.currentPageNum,
                }
                goodsListAdmin(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.infoList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            openedDialog() {

            },
            closeDialog() {
                this.resetForm()
                this.btnChoose = null
            },
            resetForm() {
                this.dialogVisible = false;
                this.addForm = {
                    goods_id: null,
                    note: '',
                }
            },
            takeOff(row) {
                this.btnChoose = 'takeOff'
                this.dialogVisible = true
                this.addForm.goods_id = row.goods_id
            },
            delBtn(row) {
                this.btnChoose = 'del'
                this.dialogVisible = true
                this.addForm.goods_id = row.goods_id
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('goods_id', this.addForm.goods_id)
                        formData.append('note', this.addForm.note)
                        if (this.btnChoose == 'del') {
                            goods_adminDelGoods(formData).then((res) => {
                                this.$message.success(res.msg)
                                this.getList()
                                this.dialogVisible = false
                                this.resetForm()
                            }).catch((err) => {
                                console.error(err)
                            })
                        } else if (this.btnChoose == 'takeOff') {
                            goods_downGoods(formData).then((res) => {
                                this.$message.success(res.msg)
                                this.getList()
                                this.dialogVisible = false
                                this.resetForm()
                            }).catch((err) => {
                                console.error(err)
                            })
                        }
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .goods-img {
        max-width: 200px;
        height: 100px;
    }

    ::v-deep .custom-dialog {
        min-width: 500px;
    }
</style>